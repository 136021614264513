var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-5 py-5"},[_c('v-data-table',{staticClass:"elevation-3 px-3 datatable-materials",attrs:{"show-expand":_vm.isThereAnyMaterialThatHasSapCode,"headers":_vm.wrappedHeaders,"items":_vm.materials,"items-per-page":20,"item-key":"id","show-select":"","no-data-text":"Sin materiales"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"red darken-3","dark":false,"size":"small","disabled":!_vm.materialsSelected.length ||
            (_vm.currentCubage && _vm.currentCubage.isClosed)},on:{"click":_vm.openModalDeleteMultipleMaterials}},[_c('span',{staticClass:"text-white"},[_vm._v(" Eliminar materiales ")])]),_c('v-btn',{attrs:{"color":"blue darken-3","dark":false,"size":"small","disabled":_vm.currentCubage && _vm.currentCubage.isClosed},on:{"click":_vm.openModalAddMaterials}},[_c('span',{staticClass:"text-white"},[_vm._v(" Agregar Material ")])]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Estás seguro de eliminar el material?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-3","text":""}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Eliminar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.material",fn:function(ref){
            var item = ref.item;
return [(_vm.isFavorite(item))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle ")]):_c('span')]}},{key:"item.document_erp",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-datatable-materials"},[_vm._v(_vm._s(item.document_erp ? item.document_erp.document_erp : "-"))])]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"text-datatable-materials my-0 mt-1 text-left"},[_vm._v(" "+_vm._s(item.material.description)+" ")])]}},{key:"item.erp_status",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-datatable-materials"},'span',attrs,false),on),[_vm._v(_vm._s(item.erp_status ? (item.erp_status == "OC" ? "SC" : "ST") : "-"))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.erp_status ? item.erp_status == "OC" ? "Solicitud de compra" : "Solicitud de traslado" : "-")+" ")])])]}},{key:"item.code",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-datatable-materials"},[_vm._v(_vm._s(item.material.code))])]}},{key:"item.units",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-datatable-materials"},[_vm._v(_vm._s(item.material.measurement.units))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(item.material.code === '-')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.openModalReplaceMaterialWithoutCode(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-find-replace ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cambiar a codificado")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.comments.length ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.showCommentsDocumentMaterial(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-message ")])],1)]}}],null,true)},[_c('span',[_vm._v("Comentarios")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.currentCubage && _vm.currentCubage.isClosed,"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}},{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [(
          (_vm.currentCubage && !_vm.currentCubage.isClosed) ||
          item.material.code === '-'
        )?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"single-line":"","value":item.quantity,"min":1,"type":"number","hide-details":""},on:{"focusout":function($event){return _vm.updateDocumentMaterial($event, item, 'quantity')}}}):_c('span',[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.dispatch",fn:function(ref){
        var item = ref.item;
return [(
          (_vm.currentCubage && !_vm.currentCubage.isClosed) ||
          item.material.code === '-'
        )?_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.dispatchsWrapped,"value":item.dispatch.description,"hide-details":""},on:{"change":function($event){return _vm.updateDocumentMaterial($event, item, 'dispatch')}}}):_c('span',[_vm._v(" "+_vm._s(item.dispatch.description)+" ")])]}},{key:"item.unit_cost",fn:function(ref){
        var item = ref.item;
return [(
          item.material.code == '-' &&
          !isNaN(item.material.unit_cost) &&
          _vm.currentCubage &&
          !_vm.currentCubage.isClosed
        )?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":0,"hide-details":"","value":item.material.unit_cost},on:{"focusout":function($event){return _vm.updateDocumentMaterial($event, item, 'cost')}}}):_c('span',[_vm._v(_vm._s(item.material && item.material.unit_cost ? ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.material.unit_cost) ))) : ("$ " + (0))))])]}},{key:"item.total_cost",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.material && item.material.unit_cost && item.quantity ? ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.total_cost) ))) : ("$ " + (0))))])]}},{key:"footer.prepend",fn:function(){return [_c('span',{staticClass:"ml-auto mr-5 text-datatable-materials font-weight-bold"},[_vm._v(" Total: "+_vm._s(("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(_vm.getTotalMaterials) ))))+" ")])]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center p-2"},[_c('section',{staticClass:"eta-request d-flex align-items-center"},[_c('label',{staticClass:"my-0 mx-2 font-weight-bold"},[_vm._v("ETA: ")]),_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.erp_status == "OC" ? _vm.getEtaFromMaterialPurchaseRequest( _vm.getCodeAndErpCodeFromMaterial(item) ) ? _vm.moment( _vm.getEtaFromMaterialPurchaseRequest( _vm.getCodeAndErpCodeFromMaterial(item) ) ).format("DD/MM/YYYY") : "Sin Datos" : "N/A")+" ")])]),_c('section',{staticClass:"status-material-request d-flex align-items-center ml-5 mr-4"},[_c('label',{staticClass:"form-label mt-2 font-weight-bold"},[_vm._v("Estatus Material: ")]),(item.erp_status == 'OC')?_c('v-chip',{staticClass:"text-white mx-2",attrs:{"label":"","small":"","color":_vm.getMappedStatusPurchaseRequest(
                  _vm.getStatusMaterialFromMaterialPurchaseRequest(
                    _vm.getCodeAndErpCodeFromMaterial(item)
                  )
                ).color}},[_vm._v(" "+_vm._s(_vm.getMappedStatusPurchaseRequest( _vm.getStatusMaterialFromMaterialPurchaseRequest( _vm.getCodeAndErpCodeFromMaterial(item) ) ).name)+" ")]):_c('p',{staticClass:"text-center mx-2 my-0",staticStyle:{"font-size":"13px"}},[_vm._v(" N/A ")])],1),_c('section',{staticClass:"status-request d-flex align-items-center"},[_c('label',{staticClass:"form-label mt-2 font-weight-bold"},[_vm._v("Estatus Solicitud: ")]),(item.erp_status == 'OC')?_c('v-chip',{staticClass:"text-white mx-2",attrs:{"label":"","small":"","color":_vm.getColorFromStatusPlataform(
                  _vm.getStatusRequestFromPurchaseRequest(
                    _vm.getCodeAndErpCodeFromMaterial(item)
                  )
                )}},[_vm._v(" "+_vm._s(_vm.getStatusRequestFromPurchaseRequest( _vm.getCodeAndErpCodeFromMaterial(item) ))+" ")]):_c('v-chip',{staticClass:"text-white mx-2",attrs:{"label":"","small":"","color":_vm.getMappedStatusRequestTransfer(
                  _vm.getStatusRequestFromTransferRequest(
                    _vm.getCodeAndErpCodeFromMaterial(item)
                  )
                ).color}},[_vm._v(" "+_vm._s(_vm.getMappedStatusRequestTransfer( _vm.getStatusRequestFromTransferRequest( _vm.getCodeAndErpCodeFromMaterial(item) ) ).name)+" ")])],1)])])]}}]),model:{value:(_vm.materialsSelected),callback:function ($$v) {_vm.materialsSelected=$$v},expression:"materialsSelected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }