<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="900px"
      @keydown="closeModalShowInitialFillRequests"
      @click:outside="closeModalShowInitialFillRequests"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"
            >Solicitudes de llenado inicial de la cubicación</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              class="px-3 elevation-1"
              :headers="tableHeaders"
              :items="initialFillRequests"
              :items-per-page="10"
              hide-default-footer
              :loading="loading"
              loading-text="Cargando solicitudes"
              item-key="id"
              no-data-text="Sin solicitudes"
            >
              <template v-slot:item.document_erp="{ item }">
                <p class="mt-3 text-left">
                  {{ item.document_erp }}
                </p>
              </template>
              <template v-slot:item.created_at="{ item }">
                <p class="mt-3 text-left">
                  {{ new Date(item.created_at).toLocaleDateString() }}
                </p>
              </template>
              <template v-slot:item.comments="{ item }">
                <p class="mt-3 text-left">
                  {{ item.comments }}
                </p>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            text
            @click="closeModalShowInitialFillRequests"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalShowInitialFillRequests: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.getInitialFillRequestsFromPlace({
      place_id: this.$route.query.sap_project
        ? this.$route.query.sap_project
        : this.$route.params.codigoLugarInstalacion,
    });
  },
  data() {
    return {
      loading: false,
      tableHeaders: [
        {
          text: "Numero de documento",
          align: "start",
          sortable: true,
          value: "document_erp",
        },
        {
          text: "Fecha de la solicitud",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Despacho",
          align: "start",
          sortable: true,
          value: "comments",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getInitialFillRequestsFromPlace: "cubage/getInitialFillRequestsFromPlace",
    }),
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      initialFillRequests: "cubage/initialFillRequests",
    }),
  },
};
</script>

<style></style>
