var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-5 py-5"},[_c('v-data-table',{staticClass:"px-3 datatable-others",attrs:{"headers":_vm.wrappedTableHeaders,"items":_vm.wrappedOthersAttachments,"items-per-page":5,"item-key":"id","show-select":false,"no-data-text":"Sin elementos actualmente"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" Otros ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.showActions)?_c('v-btn',{attrs:{"color":"blue darken-3","disabled":_vm.currentCubage && _vm.currentCubage.isClosed,"dark":false,"size":"small"},on:{"click":_vm.openModalAddOthersAttachments}},[_c('span',{staticClass:"text-white"},[_vm._v(" Agregar nuevo ")])]):_vm._e()],1)]},proxy:true},{key:"item.desc",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left mt-2"},[_vm._v(" "+_vm._s(item.desc)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]}},{key:"item.unit_cost",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":0,"hide-details":"","value":item.unit_cost},on:{"focusout":function($event){return _vm.updateOtherAttachment($event, item, 'unit_cost')}}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":0,"hide-details":"","value":item.quantity},on:{"focusout":function($event){return _vm.updateOtherAttachment($event, item, 'quantity')}}})]}},{key:"item.format",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center mt-2 mr-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","target":"_blank","href":item.download_url}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.formatFilesIcons[item.format].color}},[_vm._v(" "+_vm._s(_vm.formatFilesIcons[item.format].file)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar archivo")])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.comments.length ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.openModalShowCommentsModel(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Información")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","target":"_blank","href":item.download_url}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar archivo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.currentCubage && _vm.currentCubage.isClosed,"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.total_cost) )))))])]}},{key:"footer.prepend",fn:function(){return [_c('span',{staticClass:"ml-auto mr-5 text-datatable-board font-weight-bold"},[_vm._v(" Total: "+_vm._s(("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(_vm.getTotalMaterials) ))))+" ")])]},proxy:true}])}),_c('add-others-attachments',{attrs:{"modalAction":_vm.dialogAddOtherAttachments,"closeModalAddOthersAttachments":_vm.closeModalAddOthersAttachments},on:{"attachmentUploaded":function($event){return _vm.getCubageOthersAttachments({
        document_id: _vm.currentCubage.id,
      })}}}),_c('comments-from-model',{attrs:{"dialogShowCommentsModel":_vm.dialogShowCommentsModel,"closeModalShowCommentsModel":_vm.closeModalShowCommentsModel,"modelId":_vm.currentAttachment ? _vm.currentAttachment.id : null,"comments":_vm.comments,"modelType":"document_extras"},on:{"commentAdded":function($event){return _vm.getCubageOthersAttachments({
        document_id: _vm.currentCubage.id,
      })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }