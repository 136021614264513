<template>
  <section>
    <v-card class="px-2 py-3 mt-5 mx-2" elevation="2">
      <v-card-title>
        <h4>
          Tablero
        </h4>
      </v-card-title>
      <v-card-text>
        <board-items></board-items>

        <board-attachments></board-attachments>
      </v-card-text>
    </v-card>
    <list-materials-by-type
      class="mx-1"
      :showActions="false"
      :mainTeams="true"
      :typesMaterial="wrappedTypesMaterial"
    ></list-materials-by-type>
    <!-- <list-materials-by-type
      class="mx-1"
      :showActions="false"
      :mainTeams="true"
      :typesMaterial="wrappedTypesMaterialOthers"
    ></list-materials-by-type> -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ListMaterialsByType from "../components/ListMaterialsByType.vue";
import {
  TYPE_CUBAGE_MAIN_TEAMS,
  TYPE_CUBAGE_MAIN_TEAMS_OTHERS,
} from "@/constants/cubage";
import BoardItems from "./BoardItems.vue";
import BoardAttachments from "./BoardAttachments.vue";
export default {
  components: { ListMaterialsByType, BoardItems, BoardAttachments },
  computed: {
    ...mapGetters({
      typesMaterial: "cubage/typesMaterial",
    }),
    wrappedTypesMaterial() {
      return this.typesMaterial.filter(
        (item) => item.description === TYPE_CUBAGE_MAIN_TEAMS
      );
    },
    wrappedTypesMaterialOthers() {
      return this.typesMaterial.filter(
        (item) => item.description === TYPE_CUBAGE_MAIN_TEAMS_OTHERS
      );
    },
  },
};
</script>

<style></style>
