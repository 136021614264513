var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-5"},[_vm._l((_vm.typesMaterial),function(typeMaterial,index){return _c('section',{key:index},[_c('datatable-materials',{attrs:{"title":_vm.currentCubage && _vm.currentCubage.is_residential && !_vm.mainTeams
          ? 'Materiales'
          : typeMaterial.description == _vm.TYPE_CUBAGE_MAIN_TEAMS
          ? 'Equipos Principales'
          : typeMaterial.description,"materials":_vm.getMaterialsFromType(typeMaterial.description),"type":typeMaterial.description},on:{"openModalAddMaterials":_vm.openModalAddMaterials,"openModalReplaceMaterial":_vm.openModalReplaceMaterial,"openModalShowCommentsDocumentMaterial":_vm.openModalShowCommentsDocumentMaterial,"openModalDeleteMultipleMaterials":_vm.openModalDeleteMultipleMaterials,"deleteMaterial":_vm.openModalDeleteMaterial,"updateValuesDocumentMaterial":_vm.updateValuesDocumentMaterial}})],1)}),_c('add-materials',{attrs:{"modalAction":_vm.dialogAddMaterial,"currentTypeMaterial":_vm.currentTypeMaterial,"closeModalAddMaterials":_vm.closeModalAddMaterials},on:{"materialsAdded":function($event){return _vm.getCubageMaterials({
        document_id: _vm.currentCubage.id,
      })}}}),_c('replace-material-without-code',{attrs:{"modalAction":_vm.dialogReplaceMaterial,"closeModalReplaceMaterial":_vm.closeModalReplaceMaterial,"currentDocumentMaterial":_vm.currentDocumentMaterial},on:{"materialReplaced":function($event){return _vm.getCubageMaterials({
        document_id: _vm.currentCubage.id,
      })}}}),_c('document-material-comments',{attrs:{"dialogShowCommentsDocumentMaterial":_vm.dialogShowCommentsDocumentMaterial,"closeModalShowCommentsDocumentMaterial":_vm.closeModalShowCommentsDocumentMaterial,"documentMaterialId":_vm.currentDocumentMaterial ? _vm.currentDocumentMaterial.id : null,"comments":_vm.comments},on:{"commentAdded":function($event){return _vm.getCubageMaterials({
        document_id: _vm.currentCubage.id,
      })}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }