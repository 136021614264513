<template>
  <section class="my-5 py-5">
    <v-data-table
      class="elevation-3 px-3 datatable-materials"
      v-model="materialsSelected"
      :show-expand="isThereAnyMaterialThatHasSapCode"
      :headers="wrappedHeaders"
      :items="materials"
      :items-per-page="20"
      item-key="id"
      show-select
      no-data-text="Sin materiales"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <small class="font-weight-bold">
              {{ title }}
            </small>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-3"
            :dark="false"
            size="small"
            class="mx-3"
            :disabled="
              !materialsSelected.length ||
              (currentCubage && currentCubage.isClosed)
            "
            @click="openModalDeleteMultipleMaterials"
          >
            <span class="text-white"> Eliminar materiales </span>
          </v-btn>
          <v-btn
            color="blue darken-3"
            :dark="false"
            size="small"
            @click="openModalAddMaterials"
            :disabled="currentCubage && currentCubage.isClosed"
          >
            <span class="text-white"> Agregar Material </span>
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >¿Estás seguro de eliminar el material?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-3" text>Cancelar</v-btn>
                <v-btn color="red darken-1" @click="dialogDelete = false" text
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.material="{ item }">
        <v-icon color="primary" v-if="isFavorite(item)">
          mdi-check-circle
        </v-icon>
        <span v-else></span>
      </template>
      <template v-slot:item.document_erp="{ item }">
        <span class="text-datatable-materials">{{
          item.document_erp ? item.document_erp.document_erp : "-"
        }}</span>
      </template>
      <template v-slot:item.description="{ item }">
        <p class="text-datatable-materials my-0 mt-1 text-left">
          {{ item.material.description }}
        </p>
      </template>
      <template v-slot:item.erp_status="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text-datatable-materials">{{
              item.erp_status ? (item.erp_status == "OC" ? "SC" : "ST") : "-"
            }}</span>
          </template>
          <span>
            {{
              item.erp_status
                ? item.erp_status == "OC"
                  ? "Solicitud de compra"
                  : "Solicitud de traslado"
                : "-"
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.code="{ item }">
        <span class="text-datatable-materials">{{ item.material.code }}</span>
      </template>
      <template v-slot:item.units="{ item }">
        <span class="text-datatable-materials">{{
          item.material.measurement.units
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top v-if="item.material.code === '-'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="success"
                @click="openModalReplaceMaterialWithoutCode(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-find-replace </v-icon>
              </v-btn>
            </template>
            <span>Cambiar a codificado</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.comments.length ? 'primary' : 'secondary'"
                @click="showCommentsDocumentMaterial(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-message </v-icon>
              </v-btn>
            </template>
            <span>Comentarios</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="currentCubage && currentCubage.isClosed"
                icon
                color="error"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.quantity="{ item }">
        <v-text-field
          v-if="
            (currentCubage && !currentCubage.isClosed) ||
            item.material.code === '-'
          "
          @focusout="updateDocumentMaterial($event, item, 'quantity')"
          class="pt-0 mt-0"
          single-line
          :value="item.quantity"
          :min="1"
          type="number"
          hide-details
        ></v-text-field>
        <span v-else>
          {{ item.quantity }}
        </span>
      </template>
      <template v-slot:item.dispatch="{ item }">
        <v-select
          v-if="
            (currentCubage && !currentCubage.isClosed) ||
            item.material.code === '-'
          "
          :items="dispatchsWrapped"
          :value="item.dispatch.description"
          class="pt-0 mt-0"
          hide-details
          @change="updateDocumentMaterial($event, item, 'dispatch')"
        ></v-select>
        <span v-else>
          {{ item.dispatch.description }}
        </span>
      </template>
      <template v-slot:item.unit_cost="{ item }">
        <v-text-field
          type="number"
          :min="0"
          v-if="
            item.material.code == '-' &&
            !isNaN(item.material.unit_cost) &&
            currentCubage &&
            !currentCubage.isClosed
          "
          @focusout="updateDocumentMaterial($event, item, 'cost')"
          class="pt-0 mt-0"
          hide-details
          :value="item.material.unit_cost"
        ></v-text-field>
        <span v-else>{{
          item.material && item.material.unit_cost
            ? `$ ${new Intl.NumberFormat("de-DE").format(
                parseInt(item.material.unit_cost)
              )}`
            : `$ ${0}`
        }}</span>
      </template>
      <template v-slot:item.total_cost="{ item }">
        <span>{{
          item.material && item.material.unit_cost && item.quantity
            ? `$ ${new Intl.NumberFormat("de-DE").format(
                parseInt(item.total_cost)
              )}`
            : `$ ${0}`
        }}</span>
      </template>
      <template v-slot:footer.prepend>
        <span class="ml-auto mr-5 text-datatable-materials font-weight-bold">
          Total:
          {{
            `$ ${new Intl.NumberFormat("de-DE").format(
              parseInt(getTotalMaterials)
            )}`
          }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="">
          <div class="d-flex justify-content-center align-items-center p-2">
            <section class="eta-request d-flex align-items-center">
              <label class="my-0 mx-2 font-weight-bold">ETA: </label>
              <p class="text-center my-0" style="font-size: 13px">
                {{
                  item.erp_status == "OC"
                    ? getEtaFromMaterialPurchaseRequest(
                        getCodeAndErpCodeFromMaterial(item)
                      )
                      ? moment(
                          getEtaFromMaterialPurchaseRequest(
                            getCodeAndErpCodeFromMaterial(item)
                          )
                        ).format("DD/MM/YYYY")
                      : "Sin Datos"
                    : "N/A"
                }}
              </p>
            </section>
            <section
              class="status-material-request d-flex align-items-center ml-5 mr-4"
            >
              <label class="form-label mt-2 font-weight-bold"
                >Estatus Material:
              </label>
              <v-chip
                v-if="item.erp_status == 'OC'"
                label
                small
                :color="
                  getMappedStatusPurchaseRequest(
                    getStatusMaterialFromMaterialPurchaseRequest(
                      getCodeAndErpCodeFromMaterial(item)
                    )
                  ).color
                "
                class="text-white mx-2"
              >
                {{
                  getMappedStatusPurchaseRequest(
                    getStatusMaterialFromMaterialPurchaseRequest(
                      getCodeAndErpCodeFromMaterial(item)
                    )
                  ).name
                }}
              </v-chip>
              <p v-else class="text-center mx-2 my-0" style="font-size: 13px">
                N/A
              </p>
            </section>
            <section class="status-request d-flex align-items-center">
              <label class="form-label mt-2 font-weight-bold"
                >Estatus Solicitud:
              </label>
              <v-chip
                v-if="item.erp_status == 'OC'"
                label
                small
                :color="
                  getColorFromStatusPlataform(
                    getStatusRequestFromPurchaseRequest(
                      getCodeAndErpCodeFromMaterial(item)
                    )
                  )
                "
                class="text-white mx-2"
              >
                {{
                  getStatusRequestFromPurchaseRequest(
                    getCodeAndErpCodeFromMaterial(item)
                  )
                }}
              </v-chip>
              <v-chip
                v-else
                label
                small
                :color="
                  getMappedStatusRequestTransfer(
                    getStatusRequestFromTransferRequest(
                      getCodeAndErpCodeFromMaterial(item)
                    )
                  ).color
                "
                class="text-white mx-2"
              >
                {{
                  getMappedStatusRequestTransfer(
                    getStatusRequestFromTransferRequest(
                      getCodeAndErpCodeFromMaterial(item)
                    )
                  ).name
                }}
              </v-chip>
            </section>
          </div>
        </td>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import moment from "moment";
import statusAndEtaRequestsMixin from "@/mixins/statusAndEtaRequestsMixin";
export default {
  mixins: [statusAndEtaRequestsMixin],
  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    materials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
      debounce: null,
      debounceTime: 200,
      dialog: false,
      dialogDelete: false,
      form: {
        quantity: "",
      },
      materialsSelected: [],
      tableHeaders: [
        {
          text: "STD",
          align: "center",
          sortable: false,
          width: "5%",
          value: "material",
        },
        {
          text: "Código",
          align: "center",
          sortable: false,
          width: "5%",
          value: "code",
        },
        {
          text: this.$route.query.sap_project ? "Código SAP" : "Código SLI",
          align: "center",
          sortable: false,
          width: "5%",
          value: "document_erp",
        },
        {
          text: "Tipo Solicitud",
          align: "center",
          sortable: false,
          width: "5%",
          value: "erp_status",
        },
        {
          text: "Descripción",
          align: "center",
          width: "30%",
          sortable: false,
          value: "description",
        },
        {
          text: "Stock disponible",
          align: "center",
          width: "10%",
          sortable: false,
          value: "available_stock",
        },
        {
          text: "Unidad",
          align: "center",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Monto unitario",
          align: "center",
          width: "10%",
          sortable: false,
          value: "unit_cost",
        },
        {
          text: "Monto Total",
          align: "center",
          width: "10%",
          sortable: false,
          value: "total_cost",
        },
        {
          text: "Despacho",
          align: "center",
          sortable: false,
          width: "15%",
          value: "dispatch",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      sendUpdateDocumentMaterial: "cubage/updateDocumentMaterial",
    }),
    openModalAddMaterials() {
      this.$emit("openModalAddMaterials", { type: this.type });
    },
    openModalDeleteMultipleMaterials() {
      this.$emit("openModalDeleteMultipleMaterials", {
        materials: this.materialsSelected.map((item) => item.id),
      });
    },
    openModalReplaceMaterialWithoutCode(item) {
      this.$emit("openModalReplaceMaterial", item);
    },
    showCommentsDocumentMaterial(item) {
      this.$emit("openModalShowCommentsDocumentMaterial", { document: item });
    },
    deleteItem({ id, material }) {
      this.$emit("deleteMaterial", { id, name: material.description });
    },
    getCodeAndErpCodeFromMaterial(material) {
      return {
        sap_code: material.solicitud_erp,
        code: material.material.code,
      };
    },
    async updateDocumentMaterial(event, item, field) {
      let value = event.target ? event.target.value : event;
      if (value) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(async function () {
          let payload = {
            id: item.id,
            created_at: item.created_at,
            created_by: self.user.email,
            dispatch: field === "dispatch" ? value : item.dispatch.description,
            quantity:
              field === "quantity"
                ? value >= 1
                  ? Math.trunc(value)
                  : 1
                : item.quantity,
            status: true,
            cost:
              field === "cost"
                ? value
                : item.material.unit_cost
                ? item.material.unit_cost
                : 0,
          };
          const { data } = await self.sendUpdateDocumentMaterial(payload);
          self.$emit("updateValuesDocumentMaterial", {
            ...payload,
            newId: data.id,
          });
        }, this.debounceTime);
      }
    },
    isFavorite(item) {
      return (
        item.favorite ||
        item.favorite_ci ||
        item.favorite_otros ||
        item.favorite_pg ||
        item.favorite_re
      );
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      dispatchs: "cubage/dispatchs",
    }),
    wrappedHeaders() {
      if (this.isThereAnyMaterialThatHasSapCode) {
        return [
          ...this.tableHeaders,
          { text: "Estatus SAP", align: "center", value: "data-table-expand" },
        ];
      }
      return this.tableHeaders;
    },
    getTotalMaterials() {
      return this.materials.reduce(
        (total, item) => (total += item.total_cost),
        0
      );
    },
    dispatchsWrapped() {
      if (this.dispatchs.length) {
        return this.dispatchs.map((item) => item.description);
      }
      return [];
    },
    isThereAnyMaterialThatHasSapCode() {
      return this.materials.some((item) => item.solicitud_erp);
    },
  },
  watch: {
    materials(newMaterials) {
      this.materialsSelected = [];
    },
  },
};
</script>

<style>
.datatable-materials .v-data-footer__select {
  margin-left: 10px;
}

.datatable-materials .v-data-footer {
  display: flex;
  flex-direction: row-reverse;
}

.text-datatable-materials {
  font-size: 12.5px;
}
</style>
