<template>
  <section class="fixed-element">
    <h6 class="ml-3 mt-4 font-weight-bold">
      COSTOS
    </h6>
    <v-simple-table class="elevation-2 mt-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Sección
            </th>
            <th class="text-left">
              Valor
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in costs" :key="item.name">
            <td class="p-2 py-3">
              <p class="text-left" style="font-size: 12px">
                {{ item.name }}
              </p>
            </td>
            <td class="p-1">
              <p class="text-left" style="font-size: 12px">
                {{
                  `$ ${new Intl.NumberFormat("de-DE").format(
                    parseInt(getTotalDocumentMaterialsByType(item))
                  )}`
                }}
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  TYPE_CUBAGE_MAIN_TEAMS,
  TYPE_CUBAGE_MAIN_TEAMS_OTHERS,
} from "@/constants/cubage";
import { DISPATCH_SUB_CONTRACT } from "@/constants/cubage";

export default {
  computed: {
    ...mapGetters({
      cubageMaterials: "cubage/cubageMaterials",
      cubageMaterialsExecution: "cubage/cubageMaterialsExecution",
      cubageOthersAttachments: "cubage/cubageOthersAttachments",
      cubageBoardPanels: "cubage/cubageBoardPanels",
    }),
    costs() {
      return [
        { name: "Equipos principales", isPrincipalMaterial: true },
        { name: "Materiales", isCubageMaterial: true },
        { name: "Ejecución", isExecution: true },
        { name: "Otros", isOthers: true },
        { name: "Total", isTotal: true },
      ];
    },
  },
  methods: {
    getTotalDocumentMaterialsByType(item) {
      if (item.isTotal) {
        return (
          this.cubageMaterials
            .filter(
              (material) =>
                material.status &&
                material.dispatch.description !== DISPATCH_SUB_CONTRACT
            )
            .reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.total_cost,
              0
            ) +
          this.cubageMaterialsExecution.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.total_cost,
            0
          ) +
          this.cubageOthersAttachments.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.total_cost,
            0
          ) +
          this.cubageBoardPanels.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.total_cost,
            0
          )
        );
      } else if (item.isExecution) {
        return this.cubageMaterialsExecution.reduce(
          (accumulator, currentValue) => accumulator + currentValue.total_cost,
          0
        );
      } else if (item.isOthers) {
        return this.cubageOthersAttachments.reduce(
          (accumulator, currentValue) => accumulator + currentValue.total_cost,
          0
        );
      } else {
        let boardPanelsTotal = item.isPrincipalMaterial
          ? this.cubageBoardPanels.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.total_cost,
              0
            )
          : 0;
        return (
          boardPanelsTotal +
          this.cubageMaterials
            .filter(
              (material) =>
                (item.isPrincipalMaterial
                  ? material.dispatch.description !== DISPATCH_SUB_CONTRACT &&
                    material.type &&
                    (material.type.description === TYPE_CUBAGE_MAIN_TEAMS ||
                      material.type.description ===
                        TYPE_CUBAGE_MAIN_TEAMS_OTHERS)
                  : material.dispatch.description !== DISPATCH_SUB_CONTRACT &&
                    material.type &&
                    material.type.description !== TYPE_CUBAGE_MAIN_TEAMS &&
                    material.type.description !==
                      TYPE_CUBAGE_MAIN_TEAMS_OTHERS) && material.status
            )
            .reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.total_cost,
              0
            )
        );
      }
    },
  },
};
</script>

<style></style>
