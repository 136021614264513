<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalCopyFromTemplate"
      @click:outside="closeModalCopyFromTemplate"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Copiar desde template</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="$v.form.template_id.$model"
                  :error="
                    $v.form.template_id.$invalid && $v.form.template_id.$dirty
                  "
                  :items="templates"
                  :disabled="loading"
                  label="Template"
                  item-text="template_name"
                  item-value="id"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalCopyFromTemplate"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn color="blue darken-3" :loading="loading" @click="handleSubmit">
            <small class="text-white">
              Copiar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";

export default {
  mounted() {
    this.getTemplates({
      user: this.user.email,
    });
  },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCopyFromTemplate: {
      type: Function,
      default: () => {},
    },
  },
  validations: {
    form: {
      template_id: { required },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        template_id: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getTemplates: "cubageTemplate/getTemplates",
      cloneTemplateToDocument: "cubageTemplate/cloneTemplateToDocument",
    }),
    resetForm() {
      this.form = {
        template_id: null,
      };
    },
    async handleSubmit() {
      this.$swal
        .fire({
          icon: "info",
          title:
            "¿Estás seguro de copiar los datos de este template?. Ten en cuenta que todos los datos que estan actualmente asociados a la cubicación seran reemplazados",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#1565C0",
          confirmButtonText: "Aceptar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              template_id: this.form.template_id,
              document_id: this.currentCubage.id,
              user: this.user.email,
            };
            const resp = await this.cloneTemplateToDocument(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text:
                  "Ha ocurrido un error al copiar la información, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.form.template_id = null;
              this.$emit("templateCopied");
              this.closeModalCopyFromTemplate();
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: "Información del template agregada correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.form.template_id = null;
              this.$emit("templateCopied");
              this.closeModalCopyFromTemplate();
            }

            this.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      templates: "cubageTemplate/templates",
      currentCubage: "cubage/currentCubage",
    }),
  },
};
</script>

<style></style>
