<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1400px"
      @keydown="executeCloseModalAddMaterials"
      @click:outside="executeCloseModalAddMaterials"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Agregar Materiales</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 pb-5 px-0">
              <div v-if="loadingSyncMaterials">
                <span class="mx-2"> Ejecutando sincronización </span>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <v-btn
                @click="sendSyncMaterials"
                :disabled="loadingSyncMaterials"
                color="primary"
                :dark="false"
                small
                class="text-white mt-4"
              >
                <i class="mdi mdi-sync me-1"></i>
                Sincronizar materiales
              </v-btn>
            </v-col>
            <v-data-table
              class="px-1 elevation-1"
              v-model="materialsSelected"
              :headers="tableHeaders"
              :items="
                paginatedMaterials.materials ? paginatedMaterials.materials : []
              "
              :items-per-page="10"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingAllMaterials"
              loading-text="Cargando materiales"
              show-select
              item-key="code"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
              @toggle-select-all="updateQuantityRowSelected"
              @item-selected="updateQuantityRowSelected"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    :disabled="loadingSyncMaterials"
                    @input="getMaterials(1)"
                    class="w-25"
                    clearable
                    placeholder="Buscar material"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <div class="d-flex align-items-center">
                    <v-checkbox
                      v-model="filters.standard_materials"
                      @click="getMaterials(1)"
                      class="mx-5 mt-1"
                      :disabled="loadingSyncMaterials"
                    >
                      <template v-slot:label>
                        <div class="mt-3">Materiales Estandar</div>
                      </template>
                    </v-checkbox>
                    <p class="mx-3 text-h6">
                      <small class="pull-right">
                        Seleccionados: {{ materialsSelected.length }}
                      </small>
                    </p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.stock="{ item }">
                <span style="font-size: 12.5px">{{
                  item.stock >= 0 ? item.stock : 0
                }}</span>
              </template>
              <template v-slot:item.code="{ item }">
                <span style="font-size: 12.5px">{{ item.code }}</span>
              </template>
              <template v-slot:item.description="{ item }">
                <p class="my-0 text-left" style="font-size: 12.5px">
                  {{ item.description }}
                </p>
              </template>
              <template v-slot:item.units="{ item }">
                <span style="font-size: 12.5px">{{
                  item.measurement.units
                }}</span>
              </template>
              <template v-slot:item.favorite="{ item }">
                <v-icon color="primary" v-if="isFavorite(item)">
                  mdi-check-circle
                </v-icon>
                <span v-else></span>
              </template>
              <template v-slot:item.cost="{ item }">
                <p class="text-right my-0" style="font-size: 12.5px">
                  {{
                    item.cost
                      ? `$ ${new Intl.NumberFormat("de-DE").format(
                          parseInt(item.cost)
                        )}`
                      : "$0"
                  }}
                </p>
              </template>
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-if="materialIsSelected(item)"
                  type="number"
                  :min="1"
                  :max="item.stock"
                  class="pt-0 mt-0"
                  @input="setMaterialQuantity($event, item)"
                  :value="
                    materialIsSelected(item) &&
                    materialIsSelected(item).quantity !== undefined
                      ? materialIsSelected(item).quantity
                      : item.stock
                  "
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                :disabled="loadingSyncMaterials"
                v-model="filters.page"
                :length="
                  paginatedMaterials.materialpages
                    ? paginatedMaterials.materialpages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="materialsSelected.length >= 1"
            @click="dialogAddMaterialWithoutCode = true"
            outlined
            color="blue darken-3"
          >
            <small> Agregar sin Código </small>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="executeCloseModalAddMaterials"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            @click="addMaterialsToCubage"
            color="blue darken-3"
            :loading="loading"
            :disabled="materialsSelected.length < 1"
          >
            <small class="text-white"> Agregar </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddMaterialWithoutCode"
      max-width="600px"
      @keydown="closeModalCreateMaterial"
      @click:outside="closeModalCreateMaterial"
    >
      <v-card>
        <v-card-title> Agregar nuevo material </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="createNewMaterialWithoutCode">
            <v-col cols="12" sm="12">
              <v-text-field
                label="Descripción"
                placeholder="Ingresa la descripción"
                v-model="$v.form.description.$model"
                :error-messages="
                  $v.form.description.$invalid && submitUpload
                    ? 'Descripción es requerida'
                    : null
                "
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="$v.form.measurement.$model"
                :error-messages="
                  $v.form.measurement.$invalid && submitUpload
                    ? 'Unidad de medida es requerida'
                    : null
                "
                :items="allUnits"
                item-text="units"
                item-value="units"
                label="Unidad de medida"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                type="number"
                v-model="$v.form.unit_cost.$model"
                :error-messages="
                  $v.form.unit_cost.$invalid && submitUpload
                    ? 'Valor es requerida'
                    : null
                "
                label="Valor"
                placeholder="Ingresa el valor"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                type="number"
                v-model="$v.form.quantity.$model"
                :error-messages="
                  $v.form.quantity.$invalid && submitUpload
                    ? 'Cantidad es requerida'
                    : null
                "
                label="Cantidad a solicitar"
                placeholder="Ingresa la cantidad a solicitar"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                solo
                v-model="$v.form.comment.$model"
                label="Comentarios"
                placeholder="Ingresa los comentarios"
              >
              </v-textarea>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            text
            @click="closeModalCreateMaterial"
            :disabled="loading"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            @click="createNewMaterialWithoutCode"
            :loading="loading"
          >
            <small class="text-white"> Crear Material </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { DEFAULT_DISPATCH } from "@/constants/cubage";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    currentTypeMaterial: {
      default: null,
    },
    closeModalAddMaterials: {
      type: Function,
      default: () => {},
    },
  },
  validations: {
    form: {
      description: { required },
      measurement: { required },
      unit_cost: { required },
      quantity: { required },
      comment: {},
    },
  },
  data() {
    return {
      dialogAddMaterialWithoutCode: false,
      loading: false,
      form: {
        description: "",
        unit_cost: "",
        measurement: null,
        quantity: "",
        comment: "",
      },
      submitUpload: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        standard_materials: true,
        filter: "",
      },
      materialsSelected: [],
      tableHeaders: [
        {
          text: "STD",
          align: "center",
          width: "3%",
          sortable: false,
          value: "favorite",
        },
        {
          text: "Stock",
          align: "start",
          width: "3%",
          sortable: false,
          value: "stock",
        },
        {
          text: "Código",
          align: "start",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "50%",
          sortable: false,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Monto Unitario",
          align: "center",
          width: "10%",
          sortable: false,
          value: "cost",
        },
        {
          text: "Cantidad a solicitar",
          align: "center",
          width: "10%",
          sortable: false,
          value: "quantity",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getAllMaterials: "cubage/getAllMaterials",
      sendAddMaterialsToCubage: "cubage/addMaterialsToCubage",
      addMaterialCustom: "cubage/addMaterialCustom",
      addCommentToDocumentMaterial: "cubage/addCommentToDocumentMaterial",
      syncAllMaterials: "cubage/syncAllMaterials",
    }),
    materialIsSelected(material) {
      return this.materialsSelected.find((item) => item.code === material.code);
    },
    setMaterialQuantity(value, material) {
      this.materialsSelected.forEach((item) => {
        if (item.code === material.code) {
          item.quantity = value >= 0 ? Math.trunc(value) : 0;
        }
      });
      this.materialsSelected = [...this.materialsSelected];
    },
    updateQuantityRowSelected({ item, items, value }) {
      if (value) {
        let materials = items ? items : [item];
        materials.forEach((material) => {
          this.materialsSelected.push(material);
          this.setMaterialQuantity(0, material);
        });
      }
    },
    executeCloseModalAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.getMaterials(1);
        this.closeModalAddMaterials($event);
      }
    },
    closeModalCreateMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.dialogAddMaterialWithoutCode = false;
      }
    },
    resetForm() {
      this.form = {
        description: "",
        unit_cost: "",
        measurement: null,
        quantity: "",
        comment: "",
      };
      this.submitUpload = false;
      this.materialsSelected = [];
      this.filters.filter = "";
    },
    async createNewMaterialWithoutCode() {
      this.submitUpload = true;
      if (!this.$v.form.$invalid) {
        this.loading = true;
        let payload = {
          description: this.form.description,
          measurement: this.form.measurement,
        };
        const resp = await this.addMaterialCustom(payload);
        if (resp.status === 200) {
          const customMaterialCreated = resp.data;
          const respAddMaterialToCubage = await this.sendAddMaterialsToCubage({
            document_id: this.currentCubage.id,
            materials_custom: {
              [customMaterialCreated.id]: [
                Number(this.form.quantity),
                this.form.unit_cost,
              ],
            },
            materials: {},
            type: this.currentTypeMaterial,
            dispatch: DEFAULT_DISPATCH,
            created_by: this.user.email,
          });
          if (respAddMaterialToCubage.status == 200) {
            if (this.form.comment) {
              await this.updateCommentDocumentMaterial({
                id: respAddMaterialToCubage.data[0].id,
              });
            }
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: "Material sin código agregado correctamente",
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$emit("materialsAdded");
            this.closeModalCreateMaterial();
            this.closeModalAddMaterials();
          }
        }
        this.loading = false;
      }
    },
    async updateCommentDocumentMaterial({ id }) {
      let payload = {
        docmaterial_id: id,
        comment: this.form.comment,
        created_by: this.user.email,
      };
      await this.addCommentToDocumentMaterial(payload);
    },
    async addMaterialsToCubage() {
      let self = this;
      let materials = {};
      this.materialsSelected.forEach((material) => {
        materials[material.code] =
          material.quantity >= 1 ? material.quantity : 0;
      });
      if (Object.values(materials).find((item) => item <= 0) === 0) {
        return self.$swal({
          icon: "error",
          title: "Error",
          text: "La cantidad minima a solicitar por material es de 1",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.loading = true;
      let payload = {
        document_id: this.currentCubage.id,
        materials,
        materials_custom: {},
        type: this.currentTypeMaterial,
        dispatch: DEFAULT_DISPATCH,
        created_by: this.user.email,
      };
      const resp = await this.sendAddMaterialsToCubage(payload);
      if (resp.status == 200) {
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "Materiales agregados correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$emit("materialsAdded");
        this.materialsSelected = [];
        this.closeModalAddMaterials();
      }
      this.loading = false;
    },
    async getMaterials(page = 1) {
      if (!this.loadingSyncMaterials) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(async function () {
          self.filters.page = page;
          await self.getAllMaterials({
            ...self.filters,
            is_sap: self.$route.query.sap_project ? true : undefined,
            business_unit:
              self.projectInfo && self.projectInfo.business_unit.sap_code
                ? self.projectInfo.business_unit.sap_code
                : undefined,
          });
        }, self.debounceTime);
      }
    },
    async sendSyncMaterials() {
      this.$swal({
        icon: "info",
        title: "Atención",
        text: "La sincronización puede tardar unos minutos, una vez sincronizado el botón se habilitara nuevamente",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 6000,
        timerProgressBar: true,
      });
      const response = await this.syncAllMaterials();
      if (response.status == 500) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intenta de nuevo mas tarde",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 200) {
        await this.getMaterials();
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: `Listado de materiales sincronizados con éxito`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    isFavorite(item) {
      return (
        item.favorite ||
        item.favorite_ci ||
        item.favorite_otros ||
        item.favorite_pg ||
        item.favorite_re
      );
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      allUnits: "cubage/allUnits",
      paginatedMaterials: "cubage/paginatedMaterials",
      currentCubage: "cubage/currentCubage",
      loadingAllMaterials: "cubage/loading",
      loadingSyncMaterials: "cubage/loadingSyncMaterials",
      projectInfo: "project/projectInfo",
    }),
  },
  watch: {
    "filters.page": function (page) {
      this.getMaterials(page);
    },
  },
};
</script>

<style></style>
