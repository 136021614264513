<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      scrollable
      @keydown="closeModalCreateTemplateFromCubage"
      @click:outside="closeModalCreateTemplateFromCubage"
    >
      <v-card>
        <v-card-title>
          <v-toolbar flat>
            <span class="text-h6"
              >Crear template a partir de esta cubicación</span
            >
          </v-toolbar>
          <v-container>
            <v-form @submit.prevent="handleSubmit" class="w-100">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="$v.form.template_name.$model"
                  :error="
                    $v.form.template_name.$invalid &&
                      $v.form.template_name.$dirty
                  "
                  :disabled="loading"
                  label="Nombre del template"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="pt-0">
                <div class="text-right">
                  <v-btn
                    class=""
                    color="blue darken-3"
                    :loading="loading"
                    @click="handleSubmit"
                  >
                    <small class="text-white">
                      Crear
                    </small>
                  </v-btn>
                </div>
              </v-col>
            </v-form>
            <v-card-title class="pl-0 pb-0">
              Listado actual de templates
            </v-card-title>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container class="pt-0">
            <v-card class="mx-auto">
              <v-list two-line>
                <template v-for="(template, index) in templates">
                  <v-list-item
                    two-line
                    v-if="template"
                    :key="template.id + 1000"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        template.template_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="mt-1">
                        Ultima actualización:
                        {{ new Date(template.created_at).toLocaleDateString() }}
                        {{
                          new Date(template.created_at)
                            .toLocaleTimeString()
                            .slice(0, -3)
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="index < templates.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalCreateTemplateFromCubage"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { formatDateToHuman } from "@/helpers/common";
import { authUsuarioComputed } from "@/state/helpers";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateTemplateFromCubage: {
      type: Function,
      default: () => {},
    },
  },
  validations: {
    form: {
      template_name: { required },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        template_name: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getTemplates: "cubageTemplate/getTemplates",
      createTemplate: "cubageTemplate/createTemplate",
      cloneDocumentToTemplate: "cubageTemplate/cloneDocumentToTemplate",
    }),
    resetForm() {
      this.form = {
        template_name: null,
      };
    },
    async handleSubmit() {
      this.loading = true;
      let self = this;
      let templateExisting = null;
      let payload = {
        template_name: this.form.template_name,
        user: this.user.email,
      };
      const resp = await this.createTemplate(payload);
      if (resp.status === 422) {
        this.loading = false;
        templateExisting = resp.data.detail;
        this.$swal
          .fire({
            icon: "warning",
            title:
              "Ya existe un template con este nombre. Selecciona una de las siguientes opciones",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Sobreescribir template antiguo",
            cancelButtonText: "Cambiar nombre",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await self.cloneDocument(templateExisting);
            } else {
              return;
            }
          });
      }
      if (resp.status === 200) {
        await self.cloneDocument(resp.data);
      }
    },
    async cloneDocument(templateToClone) {
      this.loading = true;
      let payload_clone = {
        document_id: this.currentCubage.id,
        template_id: templateToClone.id,
        user: this.user.email,
      };
      const respClone = await this.cloneDocumentToTemplate(payload_clone);
      if (respClone.status == 200) {
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "Template creado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.template_name = "";
        await this.getTemplates({
          user: this.user.email,
        });
        this.$emit("newTemplateAdded");
      }
      this.closeModalCreateTemplateFromCubage();
      this.loading = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      templates: "cubageTemplate/templates",
    }),
  },
};
</script>

<style></style>
