import {
    GET_MAPPED_STATUS_PURCHASE_REQUEST,
    GET_COLOR_FROM_STATUS_PURCHASE,
  } from "@/constants/supplying/purchaseRequests";
import { GET_MAPPED_STATUS_TRANSFER_REQUEST } from "@/constants/supplying/transferRequest";  
import { TYPES_REQUESTS_SAP } from "@/constants/requestMaterial";
import { mapActions } from "vuex";
import { mapGetters } from "vuex/dist/vuex.common.js";
export default {
    methods: {
        ...mapActions({
            getRequestsDelivery: "supplying/getRequestsDeliveryFromSapCodes",
            getPurchaseRequests: "supplying/getPurchaseRequestsFromSapCodes",
            setCurrentSapCodesToPurchase: "supplying/setCurrentSapCodesToPurchase",
            setCurrentSapCodesToTransfer: "supplying/setCurrentSapCodesToTransfer",
            setSapCodeToCurrentSapCodes: "supplying/setSapCodeToCurrentSapCodes"
          }),      
        async setSapCodesAndGetStatusRequests(requests) {
            let self = this
            requests.forEach((request) => {
              self.setSapCodeToCurrentSapCodes(
                {
                   request_type: request.sap_request_type.name,
                   sap_code: request.sap_number
                }
              )
            });
            if (this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[0]].length) {
              const response = await this.getRequestsDelivery({
                sap_code_filter: `${[
                  ...this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[0]],
                ]}`,
              });
              this.setCurrentSapCodesToTransfer(response.items)
            }
            if (this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[1]].length) {
              const response = await this.getPurchaseRequests({
                sap_code_filter: `${[
                  ...this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[1]],
                ]}`,
              });
              this.setCurrentSapCodesToPurchase(response.items)
            }
          },
          getEtaFromMaterialPurchaseRequest(materialParam) {
            return (
              this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[1]]
                .find(
                  (requestPurchase) =>
                    requestPurchase.sap_code == materialParam.sap_code
                )
                ?.items.find(({ material }) => material.code == materialParam.code)
                ?.purchase_order_items.reduce((latest, current) => {
                  if (!current?.delivery_date) {
                    return latest?.delivery_date;
                  }
                  if (!latest?.delivery_date) {
                    return current?.delivery_date;
                  }
                  return new Date(current.delivery_date) > new Date(latest.delivery_date) ? current.delivery_date : latest.delivery_date
              }, null) || null
            );
          },
          getPurchaseOrdersFromMaterialPurchaseRequest(materialParam) {
            return (
              this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[1]]
                .find(
                  (requestPurchase) =>
                    requestPurchase.sap_code == materialParam.sap_code
                )
                ?.items.find(({ material }) => material.code == materialParam.code)
                ?.purchase_order_items || []
            );
          },
          getStatusMaterialFromMaterialPurchaseRequest(materialParam) {
            return (
              this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[1]]
                .find(
                  (requestPurchase) =>
                    requestPurchase.sap_code == materialParam.sap_code
                )
                ?.items.find(({ material }) => material.code == materialParam.code)
                ?.status || "-"
            );
          },
          getStatusRequestFromPurchaseRequest(materialParam) {
            return (
              this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[1]].find(
                (requestPurchase) =>
                  requestPurchase.sap_code == materialParam.sap_code
              )?.status_plataforma || "-"
            );
          },
          getStatusRequestFromTransferRequest(materialParam) {
            return (
              this.currentSapCodes[Object.keys(TYPES_REQUESTS_SAP)[0]].find(
                (requestPurchase) =>
                  requestPurchase.sap_code == materialParam.sap_code
              )?.manual_status || null
            );
          },
          getMappedStatusPurchaseRequest(status) {
            return GET_MAPPED_STATUS_PURCHASE_REQUEST(status);
          },
          getColorFromStatusPlataform(status) {
            return GET_COLOR_FROM_STATUS_PURCHASE(status);
          },
          getMappedStatusRequestTransfer(requestTransferStatus) {
            return GET_MAPPED_STATUS_TRANSFER_REQUEST(requestTransferStatus);
          },
    },
    computed: {
        ...mapGetters({
            currentSapCodes: "supplying/currentSapCodes"
        })
    }
}