<template>
  <list-materials-by-type
    :typesMaterial="wrappedTypesMaterial"
  ></list-materials-by-type>
</template>

<script>
import { mapGetters } from "vuex";
import ListMaterialsByType from "../components/ListMaterialsByType.vue";
import {
  TYPE_CUBAGE_MAIN_TEAMS,
  TYPE_CUBAGE_MAIN_TEAMS_OTHERS,
  TYPE_ADITIONAL,
} from "@/constants/cubage";
export default {
  components: { ListMaterialsByType },
  computed: {
    ...mapGetters({
      typesMaterial: "cubage/typesMaterial",
      currentCubage: "cubage/currentCubage",
    }),
    wrappedTypesMaterial() {
      return this.typesMaterial.filter((item) => {
        if (this.currentCubage && this.currentCubage.is_residential) {
          return item.description == TYPE_ADITIONAL;
        }
        return (
          item.description !== TYPE_CUBAGE_MAIN_TEAMS &&
          item.description !== TYPE_CUBAGE_MAIN_TEAMS_OTHERS
        );
      });
    },
  },
};
</script>

<style></style>
