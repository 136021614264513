export const MAP_STATUS_PURCHASE_REQUESTS = {
    'bost_Open' : {name: 'Pendiente', color: 'red darken-2', status: 1, value: false},
    'bost_Close': {name: 'Cerrado', color: 'green darken-2', status: 2, value: true}
}

export const GET_MAPPED_STATUS_PURCHASE_REQUEST = (status) => {
    return MAP_STATUS_PURCHASE_REQUESTS[status]
      ? MAP_STATUS_PURCHASE_REQUESTS[status]
      : { name: status, color: "grey darken-3" };
}

export const GET_COLOR_FROM_STATUS_PURCHASE = (status) => {
    switch (true) {
      case status.includes("Parcialmente"):
        return "orange darken-1";
      case status.includes("Comprado"):
        return "purple darken-1";
      case status.includes("Recibido"):
        return "green darken-1";
      case status.includes("Cancelado"):
        return "red darken-1";
      default:
        return "primary";
    }
}