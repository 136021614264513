<template>
  <section class="mt-5">
    <section v-for="(typeMaterial, index) in typesMaterial" :key="index">
      <datatable-materials
        :title="
          currentCubage && currentCubage.is_residential && !mainTeams
            ? 'Materiales'
            : typeMaterial.description == TYPE_CUBAGE_MAIN_TEAMS
            ? 'Equipos Principales'
            : typeMaterial.description
        "
        :materials="getMaterialsFromType(typeMaterial.description)"
        @openModalAddMaterials="openModalAddMaterials"
        @openModalReplaceMaterial="openModalReplaceMaterial"
        @openModalShowCommentsDocumentMaterial="
          openModalShowCommentsDocumentMaterial
        "
        @openModalDeleteMultipleMaterials="openModalDeleteMultipleMaterials"
        @deleteMaterial="openModalDeleteMaterial"
        @updateValuesDocumentMaterial="updateValuesDocumentMaterial"
        :type="typeMaterial.description"
      ></datatable-materials>
    </section>
    <!-- Modals -->
    <add-materials
      :modalAction="dialogAddMaterial"
      :currentTypeMaterial="currentTypeMaterial"
      :closeModalAddMaterials="closeModalAddMaterials"
      @materialsAdded="
        getCubageMaterials({
          document_id: currentCubage.id,
        })
      "
    ></add-materials>

    <replace-material-without-code
      :modalAction="dialogReplaceMaterial"
      :closeModalReplaceMaterial="closeModalReplaceMaterial"
      :currentDocumentMaterial="currentDocumentMaterial"
      @materialReplaced="
        getCubageMaterials({
          document_id: currentCubage.id,
        })
      "
    ></replace-material-without-code>

    <document-material-comments
      :dialogShowCommentsDocumentMaterial="dialogShowCommentsDocumentMaterial"
      :closeModalShowCommentsDocumentMaterial="
        closeModalShowCommentsDocumentMaterial
      "
      :documentMaterialId="
        currentDocumentMaterial ? currentDocumentMaterial.id : null
      "
      :comments="comments"
      @commentAdded="
        getCubageMaterials({
          document_id: currentCubage.id,
        })
      "
    ></document-material-comments>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddMaterials from "../materials/AddMaterials.vue";
import ReplaceMaterialWithoutCode from "../components/ReplaceMaterialWithoutCode.vue";
import DatatableMaterials from "../materials/DatatableMaterials.vue";
import { authUsuarioComputed } from "@/state/helpers";
import DocumentMaterialComments from "./DocumentMaterialComments.vue";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { TYPE_CUBAGE_MAIN_TEAMS } from "@/constants/cubage";
export default {
  components: {
    DatatableMaterials,
    AddMaterials,
    ReplaceMaterialWithoutCode,
    DocumentMaterialComments,
  },
  props: {
    typesMaterial: {
      type: Array,
      default: () => [],
    },
    mainTeams: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPE_CUBAGE_MAIN_TEAMS,
      loading: false,
      comments: [],
      dialogAddMaterial: false,
      dialogReplaceMaterial: false,
      dialogShowCommentsDocumentMaterial: false,
      currentTypeMaterial: null,
      materials: [],
      currentDocumentMaterial: null,
    };
  },
  methods: {
    ...mapActions({
      getCubageMaterials: "cubage/getCubageMaterials",
      deleteDocumentMaterial: "cubage/deleteDocumentMaterial",
      deleteMultipleDocumentMaterial: "cubage/deleteMultipleDocumentMaterial",
      sendUpdateValuesCubageDocumentMaterial:
        "cubage/updateValuesCubageDocumentMaterial",
    }),
    openModalShowCommentsDocumentMaterial({ document }) {
      this.currentDocumentMaterial = document;
      this.comments = document.comments;
      this.dialogShowCommentsDocumentMaterial = true;
    },
    closeModalShowCommentsDocumentMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentDocumentMaterial = null;
        this.dialogShowCommentsDocumentMaterial = false;
      }
    },
    openModalReplaceMaterial(currentDocumentMaterial) {
      this.currentDocumentMaterial = currentDocumentMaterial;
      this.dialogReplaceMaterial = true;
    },
    closeModalReplaceMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentDocumentMaterial = null;
        this.dialogReplaceMaterial = false;
      }
    },
    openModalAddMaterials({ type }) {
      this.currentTypeMaterial = type;
      this.dialogAddMaterial = true;
    },
    closeModalAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentTypeMaterial = null;
        this.dialogAddMaterial = false;
      }
    },
    updateValuesDocumentMaterial(documentMaterialUpdated) {
      this.sendUpdateValuesCubageDocumentMaterial(documentMaterialUpdated);
    },
    openModalDeleteMultipleMaterials({ materials }) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `Deseas eliminar los materiales seleccionados ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteMultipleDocumentMaterial({
              user_id: self.user.email,
              material_list: materials,
            });
            if (resp.status === 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: "Materiales eliminados correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
    openModalDeleteMaterial({ id, name }) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `Deseas eliminar este material: ${name} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteDocumentMaterial({
              document_material_id: id,
              user_id: self.user.email,
            });
            if (resp.status === 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: "Material eliminado correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
    getMaterialsFromType(type) {
      return this.cubageMaterials.filter(
        (material) =>
          material.type && material.type.description === type && material.status
      );
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      cubageMaterials: "cubage/cubageMaterials",
    }),
  },
};
</script>

<style></style>
