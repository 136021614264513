<template>
  <section>
    <v-overlay :value="loadingClose" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-alert
      border="left"
      color="blue light"
      text
      type="info"
      v-if="cubageMaterials.length && someCubageMaterialWithoutType"
    >
      Cubicación cerrada. Los materiales fueron cargados manualmente desde
      abastecimiento
    </v-alert>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="mb-2">
        <section
          class="text-center d-flex justify-content-center align-items-center"
        >
          <v-tooltip top v-if="currentCubageIsIncomplete">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-large
                outlined
                class="mx-3"
                color="red darken-2 text-white"
                @click="openModalSendCubage(true)"
                v-bind="attrs"
                v-on="on"
              >
                Enviar materiales restantes
              </v-btn>
            </template>
            <span
              >Esta acción envía los materiales restantes a
              {{ $route.query.sap_project ? "SAP" : "Flexline" }}, en caso de
              que haya ocurrido un problema al enviar la solicitud</span
            >
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-large
                outlined
                color="red darken-2 text-white"
                :disabled="
                  loadingClose || (currentCubage && currentCubage.isClosed)
                "
                @click="openModalSendCubage()"
                v-bind="attrs"
                v-on="on"
              >
                Enviar cubicación
              </v-btn>
            </template>
            <span
              >Esta acción ejecuta el cierre y envía la solicitud de llenado
              inicial a
              {{ $route.query.sap_project ? "SAP" : "Flexline" }} contemplando
              todos los items de la cubicación</span
            >
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                outlined
                v-if="
                  currentCubage &&
                  cubageMaterialsIsSomeMaterialRemainingToClose &&
                  !someCubageMaterialWithoutType
                "
                class="mx-2"
                color="red darken-2 text-white"
                @click="closeRemainingCurrentCubage(true)"
                v-bind="attrs"
                v-on="on"
              >
                Solicitar materiales restantes
              </v-btn>
            </template>
            <span
              >Esta acción envia la solicitud a flexline de los materiales sin
              código que fueron sustituidos por uno con código de la
              cubicación</span
            >
          </v-tooltip>
        </section>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-tooltip
          top
          v-if="cubageMaterials.length && !someCubageMaterialWithoutType"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              color="blue darken-2 text-white"
              :loading="loading"
              class=""
              @click="openModalCreateTemplateFromCubage"
              v-bind="attrs"
              v-on="on"
            >
              Guardar Template
            </v-btn>
          </template>
          <span
            >Esta acción permite crear un nuevo template a partir de esta
            cubicación</span
          >
        </v-tooltip>
        <v-tooltip top v-if="!someCubageMaterialWithoutType">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="mx-2"
              color="blue darken-2 text-white"
              :loading="loading"
              @click="openModalCopyFromTemplate"
              v-bind="attrs"
              v-on="on"
            >
              Cargar
            </v-btn>
          </template>
          <span
            >Esta acción permite cargar todos los elementos de un template
            previamente creado a esta cubicación</span
          >
        </v-tooltip>
        <v-tooltip top v-if="!someCubageMaterialWithoutType">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="mt-lg-0 mt-md-3"
              color="blue darken-2 text-white"
              :loading="loading"
              @click="openModalUploadMaterialsExcelFromCubage"
              v-bind="attrs"
              v-on="on"
            >
              Carga masiva de materiales
            </v-btn>
          </template>
          <span
            >Esta acción permite cargar masivamente un listado de materiales a
            esta cubicación mediante un archivo excel con un formato base</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="mx-2 float-right"
              color="grey darken-2 text-white"
              @click="showCosts = !showCosts"
              v-bind="attrs"
              v-on="on"
            >
              {{ showCosts ? "Ocultar costos" : "Mostrar costos" }}
              <v-icon small class="pl-2">
                {{
                  showCosts
                    ? "mdi-arrow-collapse-right"
                    : "mdi-arrow-collapse-left"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span
            >Esta acción permite mostrar/ocultar un panel de costos totales de
            la cubicación</span
          >
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="float-right"
              color="blue darken-2 text-white"
              @click="openModalShowInitialFillRequests"
              v-bind="attrs"
              v-on="on"
            >
              Ver detalles
            </v-btn>
          </template>

          <span
            >Esta acción permite ver el listado de solicitudes de llenado
            inicial realizadas en esta cubicación</span
          >
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              class="float-right mr-2 mt-lg-0 mt-md-3"
              color="green darken-2"
              :loading="loadingExcel"
              @click="downloadExcelFromCubage"
              v-bind="attrs"
              v-on="on"
            >
              Descargar Excel
            </v-btn>
          </template>

          <span
            >Esta acción permite descargar un archivo excel con el resumen
            detallado de la cubicación</span
          >
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        :md="showCosts ? 10 : 12"
        sm="12"
        class="transition-easy-out"
      >
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab key="principal"> Equipos Principales </v-tab>
          <v-tab key="materials"> Materiales </v-tab>
          <v-tab key="execution"> Ejecución </v-tab>
          <v-tab key="others"> Otros </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="principal">
            <main-teams></main-teams>
          </v-tab-item>
          <v-tab-item key="materials">
            <cubicle-materials></cubicle-materials>
          </v-tab-item>
          <v-tab-item key="execution">
            <execution></execution>
          </v-tab-item>
          <v-tab-item key="others">
            <others-attachments></others-attachments>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="2" sm="12" class="px-0" v-show="showCosts">
        <total-costs></total-costs>
      </v-col>
    </v-row>
    <!-- Modal para ver solicitudes de llenado inicial de la cubicación -->
    <show-initial-fill-requests
      :modalAction="dialogShowInitialFillRequests"
      :closeModalShowInitialFillRequests="closeModalShowInitialFillRequests"
    ></show-initial-fill-requests>

    <!-- Modal de copiar template -->
    <copy-to-template-cubage
      :modalAction="dialogCopyToTemplate"
      :closeModalCopyToTemplate="closeModalCopyToTemplate"
      @materialsCopied="
        getCubageMaterials({
          document_id: currentCubage.id,
        })
      "
    ></copy-to-template-cubage>
    <!-- Modal de crear template desde una cubicación -->
    <create-template-from-cubage
      :modalAction="dialogCreateTemplateFromCubage"
      :closeModalCreateTemplateFromCubage="closeModalCreateTemplateFromCubage"
      @newTemplateAdded="getTemplates"
    ></create-template-from-cubage>

    <!-- Modal de copiar desde un template -->
    <copy-from-template
      :modalAction="dialogCopyFromTemplate"
      :closeModalCopyFromTemplate="closeModalCopyFromTemplate"
      @templateCopied="
        getCubageMaterials({
          document_id: currentCubage.id,
        })
      "
    ></copy-from-template>
    <!-- Modal Upload Materials From Excel -->
    <upload-materials-excel-from-cubage
      :modalAction="dialogUploadMaterialsExcelFromCubage"
      :closeModalUploadMaterialsExcelFromCubage="
        closeModalUploadMaterialsExcelFromCubage
      "
      @materialsUploaded="
        getCubageMaterials({
          document_id: currentCubage.id,
        })
      "
    ></upload-materials-excel-from-cubage>

    <!-- Modal cerrar cubicación -->
    <v-dialog
      v-model="dialogSendCubage"
      max-width="700px"
      @keydown="closeModalSendCubage"
      @click:outside="closeModalSendCubage"
    >
      <v-card>
        <v-card-title class="p-0">
          <v-toolbar color="red darken-2" dark>
            <v-toolbar-title>
              <span class="text-h6">Enviar cubicación</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <p class="mt-5 font-weight-bold px-1">
            ¿ Deseas cerrar esta cubicación ? una vez cerrada, se envía la
            solicitud de los materiales y solo podras modificar los materiales
            sin código
          </p>
          <section
            v-if="$route.query.sap_project"
            class="text-center mt-5 pt-5"
          >
            <label class="">
              Selecciona la etapa del proyecto para la cubicación
            </label>
            <v-autocomplete
              class="w-50 mx-auto"
              placeholder="Etapa del proyecto"
              v-model="form.sap_stage"
              :items="availableStagesProjects"
              item-value="sap_code"
              item-text="name"
            ></v-autocomplete>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            :disabled="loadingClose"
            @click="closeModalSendCubage($event)"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            :disabled="loadingClose"
            color="red darken-3"
            class="text-white"
            @click="closeCurrentCubage"
          >
            <small> Cerrar cubicación </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import CubicleMaterials from "@/components/proyectos/lugarInstalacion/cubicles/materials/index.vue";
import MainTeams from "@/components/proyectos/lugarInstalacion/cubicles/mainTeams/index.vue";
import Execution from "@/components/proyectos/lugarInstalacion/cubicles/execution/index.vue";
import OthersAttachments from "@/components/proyectos/lugarInstalacion/cubicles/others/Attachments.vue";
import CopyToTemplateCubage from "./materials/CopyToTemplateCubage.vue";
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import CreateTemplateFromCubage from "./components/CreateTemplateFromCubage.vue";
import CopyFromTemplate from "./components/CopyFromTemplate.vue";
import ShowInitialFillRequests from "./components/ShowInitialFillRequests.vue";
import UploadMaterialsExcelFromCubage from "./components/UploadMaterialsExcelFromCubage.vue";
import TotalCosts from "./components/TotalCosts.vue";
import { TYPES_REQUESTS_SAP } from "@/constants/requestMaterial";
import statusAndEtaRequestsMixin from "@/mixins/statusAndEtaRequestsMixin";

export default {
  mixins: [statusAndEtaRequestsMixin],
  components: {
    CubicleMaterials,
    CopyToTemplateCubage,
    MainTeams,
    Execution,
    OthersAttachments,
    CreateTemplateFromCubage,
    CopyFromTemplate,
    ShowInitialFillRequests,
    UploadMaterialsExcelFromCubage,
    TotalCosts,
  },
  async mounted() {
    await this.getProjectInfo({ project_code: this.$route.query.sap_project });
    await this.obtenerProyectoUsuario();
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.lugarInstalacion = {
      codigo_lugar_instalacion: this.codigoLugarInstalacion,
      codigo_proyecto: this.codigoProyecto,
    };
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
    await this.getCubageFromPlaceId({
      place_id: this.$route.query.sap_project
        ? this.$route.query.sap_project
        : this.codigoLugarInstalacion,
      user_id: this.user.email,
      is_sap: this.$route.query.sap_project ? true : undefined,
    });
    this.getAllTypesMaterial();
    this.getAllUnits();
    this.getAllMaterials({
      page: 1,
      is_sap: this.$route.query.sap_project ? true : undefined,
      standard_materials: true,
      business_unit: this.projectInfo.business_unit.sap_code,
    });
    this.getAllDispatchs();

    //SAP
    if (this.$route.query.sap_project) {
      await this.getStagesAvailableFromProject({
        project_code: this.$route.query.sap_project,
      });
    }
  },
  destroyed() {
    this.setDefaultCurrentCubage();
    this.setDefaultCubageMaterials();
    this.cleanInitialFillRequests();
    this.resetCurrentSapCodes();
  },
  data() {
    return {
      tab: "materials",
      loading: false,
      loadingClose: false,
      loadingExcel: false,
      dialogSendCubage: false,
      dialogCopyToTemplate: false,
      dialogCreateTemplateFromCubage: false,
      dialogCopyFromTemplate: false,
      dialogShowInitialFillRequests: false,
      dialogUploadMaterialsExcelFromCubage: false,
      showCosts: false,
      //Data a mover a mixin
      proyectoSeleccionado: {},
      codigoProyecto: null,
      codigoLugarInstalacion: null,
      lugarInstalacion: {},
      rolModuloPermiso: {},
      form: {
        sap_stage: null,
      },
    };
  },
  methods: {
    ...proyectoMethods,
    ...authMethods,
    ...mapActions({
      setDefaultCurrentCubage: "cubage/setDefaultCurrentCubage",
      setDefaultCubageMaterials: "cubage/setDefaultCubageMaterials",
      cleanInitialFillRequests: "cubage/cleanInitialFillRequests",
      getAllTypesMaterial: "cubage/getAllTypesMaterial",
      getCubageFromPlaceId: "cubage/getCubageFromPlaceId",
      getAllMaterials: "cubage/getAllMaterials",
      getAllUnits: "cubage/getAllUnits",
      getCubageMaterials: "cubage/getCubageMaterials",
      getCubageMaterialsExecution: "cubage/getCubageMaterialsExecution",
      getCubageBoardAttachments: "cubage/getCubageBoardAttachments",
      getCubageBoardPanels: "cubage/getCubageBoardPanels",
      sendDownloadExcelFromCubage: "cubage/downloadExcelFromCubage",
      getAllDispatchs: "cubage/getAllDispatchs",
      closeCubage: "cubage/closeCubage",
      getCubageOthersAttachments: "cubage/getCubageOthersAttachments",
      getTemplates: "cubageTemplate/getTemplates",
      getInitialFillRequestsFromPlace: "cubage/getInitialFillRequestsFromPlace",
      getProjectInfo: "project/getProjectInfo",

      //SAP
      getStagesAvailableFromProject:
        "requestMaterialsSap/getStagesAvailableFromProject",
      resetCurrentSapCodes: "supplying/resetCurrentSapCodes",
    }),
    openModalSendCubage() {
      this.dialogSendCubage = true;
    },
    closeModalSendCubage($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogSendCubage = false;
      }
    },
    obtenerProyectoUsuario() {
      this.obtenerProyecto(this.$route.params.codigoProyecto)
        .then((res) => {
          this.proyectoSeleccionado = res.body;
        })
        .catch((error) => {});
    },
    openModalCopyToTemplate() {
      this.dialogCopyToTemplate = true;
    },
    closeModalCopyToTemplate($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogCopyToTemplate = false;
      }
    },
    openModalCreateTemplateFromCubage() {
      this.dialogCreateTemplateFromCubage = true;
    },
    closeModalCreateTemplateFromCubage($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogCreateTemplateFromCubage = false;
      }
    },
    openModalCopyFromTemplate() {
      this.dialogCopyFromTemplate = true;
    },
    closeModalCopyFromTemplate($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogCopyFromTemplate = false;
      }
    },
    openModalShowInitialFillRequests() {
      this.dialogShowInitialFillRequests = true;
    },
    closeModalShowInitialFillRequests($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogShowInitialFillRequests = false;
      }
    },
    openModalUploadMaterialsExcelFromCubage() {
      this.dialogUploadMaterialsExcelFromCubage = true;
    },
    closeModalUploadMaterialsExcelFromCubage() {
      this.dialogUploadMaterialsExcelFromCubage = false;
    },
    async downloadExcelFromCubage() {
      this.loadingExcel = true;
      const response = await this.sendDownloadExcelFromCubage({
        document_id: this.currentCubage.id,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `materiales_${this.lugarInstalacion.codigo_lugar_instalacion}.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loadingExcel = false;
    },
    async closeCurrentCubage() {
      if (this.$route.query.sap_project && !this.form.sap_stage) {
        return this.$swal({
          icon: "error",
          title: "Error",
          text: "Debes seleccionar la etapa del proyecto",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      let self = this;
      this.closeModalSendCubage();
      this.loadingClose = true;
      const resp = await this.closeCubage({
        document_id: self.currentCubage.id,
        user: self.user.email,
        sap_stage: self.$route.query.sap_project
          ? this.form.sap_stage
          : undefined,
      });
      if (!resp) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Has excedido el numero máximo de intentos, prueba nuevamente mas tarde ",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      if (resp && resp.status === 200) {
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "Cubicación cerrada correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        self.form.sap_stage = null;
        self.getCubageMaterials({
          document_id: self.currentCubage.id,
        });
        self.getInitialFillRequestsFromPlace({
          place_id: self.$route.query.sap_project
            ? self.$route.query.sap_project
            : self.codigoLugarInstalacion,
        });
        this.closeModalSendCubage();
      }
      if (resp.status === 400) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: resp.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      if (!resp || resp.status === 500) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, por favor intenta nuevamente mas tarde",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loadingClose = false;
    },
    closeRemainingCurrentCubage() {
      let message = `¿ Deseas solicitar los materiales restantes ?, ten en cuenta que ${
        this.getCubageMaterialsWithoutCode.length
          ? `aun quedan <b>${this.getCubageMaterialsWithoutCode.length}</b> ${
              this.getCubageMaterialsWithoutCode.length > 1
                ? "materiales"
                : "material"
            } sin código que no se van a solicitar y solo`
          : ""
      } se enviarán los materiales que hayas reemplazado`;
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: message,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#d9534f",
          confirmButtonText: sendRemaining ? "Solicitar" : "Cerrar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingClose = true;
            const resp = await this.closeCubage({
              document_id: self.currentCubage.id,
              user: this.user.email,
            });
            if (!resp) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Has excedido el numero máximo de intentos, prueba nuevamente mas tarde ",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp && resp.status === 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: sendRemaining
                  ? "Materiales solicitados correctamente"
                  : "Cubicación cerrada correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              self.getCubageMaterials({
                document_id: self.currentCubage.id,
              });
              self.getInitialFillRequestsFromPlace({
                place_id: self.$route.query.sap_project
                  ? self.$route.query.sap_project
                  : self.codigoLugarInstalacion,
              });
            }
            if (!resp || resp.status === 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, por favor intenta nuevamente mas tarde",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.loadingClose = false;
          }
        });
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      cubageMaterials: "cubage/cubageMaterials",
      availableStagesProjects: "requestMaterialsSap/availableStagesProjects",
      projectInfo: "project/projectInfo",
    }),
    currentCubageIsIncomplete() {
      return (
        this.currentCubage &&
        this.currentCubage.isClosed &&
        this.cubageMaterials
          .filter(({ material }) => {
            return material.code !== "-";
          })
          .some((material) => material.erp_status === null)
      );
    },
    someCubageMaterialWithoutType() {
      return this.cubageMaterials.some((material) => {
        return material.type === null;
      });
    },
    getCubageMaterialsWithoutCode() {
      return this.cubageMaterials.filter(({ material }) => {
        return material.code === "-";
      });
    },
    cubageMaterialsIsSomeMaterialRemainingToClose() {
      return (
        this.cubageMaterials.some((material) => material.erp_status !== null) &&
        this.cubageMaterials.some(
          (material) =>
            material.material.code !== "-" && material.erp_status === null
        )
      );
    },
  },
  watch: {
    currentCubage: {
      immediate: true,
      handler: function (cubage) {
        if (cubage && cubage.id) {
          this.getCubageMaterials({
            document_id: cubage.id,
          });
          this.getCubageMaterialsExecution({
            document_id: cubage.id,
          });
          this.getCubageBoardAttachments({
            document_id: cubage.id,
          });
          this.getCubageOthersAttachments({
            document_id: cubage.id,
          });
          this.getCubageBoardPanels({
            document_id: cubage.id,
          });
        }
      },
    },
    cubageMaterials(materials) {
      if (
        materials.length &&
        materials.some((material) => material.erp_status !== null)
      ) {
        this.setSapCodesAndGetStatusRequests(
          materials
            .filter((item) => item.erp_status)
            .map((item) => ({
              ...item,
              sap_request_type: {
                name:
                  item.erp_status !== "OC"
                    ? Object.keys(TYPES_REQUESTS_SAP)[0]
                    : Object.keys(TYPES_REQUESTS_SAP)[1],
              },
              sap_number: item.solicitud_erp,
            }))
        );
      }
    },
  },
};
</script>

<style>
.transition-easy-out {
  transition: all 0.18s ease-in-out;
}
</style>
