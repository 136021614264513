<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalCopyToTemplate"
      @click:outside="closeModalCopyToTemplate"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Copiar materiales de otra cubicación</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="$v.form.source_id.$model"
                  :error="$v.form.source_id.$invalid && $v.form.source_id.$dirty"
                  :items="wrappedCubages"
                  label="Cubicación"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalCopyToTemplate"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn color="blue darken-3" :loading="loading" @click="cloneCubage">
            <small class="text-white">
              Copiar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'

export default {
  mounted() {
    this.getCubagesWithMaterials()
  },
  props: {
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalCopyToTemplate: {
      type: Function,
      default: () => {}
    }
  },
  validations: {
    form: {
      source_id: { required }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        source_id: null
      }
    }
  },
  methods: {
    ...mapActions({
      getCubagesWithMaterials: 'cubage/getCubagesWithMaterials',
      cloneCubageFromOtherCubage: 'cubage/cloneCubageFromOtherCubage'
    }),
    resetForm() {
      this.form = {
        source_id: null
      }
    },
    async cloneCubage() {
      this.loading = true
      let payload = {
        source_id: this.form.source_id,
        target_id: this.currentCubage.id,
        user: this.user.email
      }
      const resp = await this.cloneCubageFromOtherCubage(payload)
      if (resp.status == 200) {
        this.$swal({
          icon: 'success',
          title: 'Excelente',
          text: 'Materiales copiados correctamente',
          position: 'top-end',
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true
        })
        this.$emit('materialsCopied')
        this.closeModalCopyToTemplate()
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      cubagesWithMaterials: 'cubage/cubagesWithMaterials',
      currentCubage: 'cubage/currentCubage'
    }),
    wrappedCubages() {
      if (this.currentCubage) {
        return this.cubagesWithMaterials
          .map((item) => {
            return {
              ...item,
              name: `Cubicación de punto de instalación ${item.place_id}`
            }
          })
          .filter((item) => item.id !== this.currentCubage.id)
      }
      return this.cubages
    }
  }
}
</script>

<style></style>
