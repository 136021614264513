var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-overlay',{staticClass:"align-center justify-center",attrs:{"value":_vm.loadingClose}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),(_vm.cubageMaterials.length && _vm.someCubageMaterialWithoutType)?_c('v-alert',{attrs:{"border":"left","color":"blue light","text":"","type":"info"}},[_vm._v(" Cubicación cerrada. Los materiales fueron cargados manualmente desde abastecimiento ")]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('section',{staticClass:"text-center d-flex justify-content-center align-items-center"},[(_vm.currentCubageIsIncomplete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"x-large":"","outlined":"","color":"red darken-2 text-white"},on:{"click":function($event){return _vm.openModalSendCubage(true)}}},'v-btn',attrs,false),on),[_vm._v(" Enviar materiales restantes ")])]}}],null,false,2499244809)},[_c('span',[_vm._v("Esta acción envía los materiales restantes a "+_vm._s(_vm.$route.query.sap_project ? "SAP" : "Flexline")+", en caso de que haya ocurrido un problema al enviar la solicitud")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","outlined":"","color":"red darken-2 text-white","disabled":_vm.loadingClose || (_vm.currentCubage && _vm.currentCubage.isClosed)},on:{"click":function($event){return _vm.openModalSendCubage()}}},'v-btn',attrs,false),on),[_vm._v(" Enviar cubicación ")])]}}])},[_c('span',[_vm._v("Esta acción ejecuta el cierre y envía la solicitud de llenado inicial a "+_vm._s(_vm.$route.query.sap_project ? "SAP" : "Flexline")+" contemplando todos los items de la cubicación")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                _vm.currentCubage &&
                _vm.cubageMaterialsIsSomeMaterialRemainingToClose &&
                !_vm.someCubageMaterialWithoutType
              )?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"large":"","outlined":"","color":"red darken-2 text-white"},on:{"click":function($event){return _vm.closeRemainingCurrentCubage(true)}}},'v-btn',attrs,false),on),[_vm._v(" Solicitar materiales restantes ")]):_vm._e()]}}])},[_c('span',[_vm._v("Esta acción envia la solicitud a flexline de los materiales sin código que fueron sustituidos por uno con código de la cubicación")])])],1)]),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.cubageMaterials.length && !_vm.someCubageMaterialWithoutType)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","color":"blue darken-2 text-white","loading":_vm.loading},on:{"click":_vm.openModalCreateTemplateFromCubage}},'v-btn',attrs,false),on),[_vm._v(" Guardar Template ")])]}}],null,false,1741359329)},[_c('span',[_vm._v("Esta acción permite crear un nuevo template a partir de esta cubicación")])]):_vm._e(),(!_vm.someCubageMaterialWithoutType)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","outlined":"","color":"blue darken-2 text-white","loading":_vm.loading},on:{"click":_vm.openModalCopyFromTemplate}},'v-btn',attrs,false),on),[_vm._v(" Cargar ")])]}}],null,false,582615007)},[_c('span',[_vm._v("Esta acción permite cargar todos los elementos de un template previamente creado a esta cubicación")])]):_vm._e(),(!_vm.someCubageMaterialWithoutType)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-lg-0 mt-md-3",attrs:{"small":"","outlined":"","color":"blue darken-2 text-white","loading":_vm.loading},on:{"click":_vm.openModalUploadMaterialsExcelFromCubage}},'v-btn',attrs,false),on),[_vm._v(" Carga masiva de materiales ")])]}}],null,false,1243209237)},[_c('span',[_vm._v("Esta acción permite cargar masivamente un listado de materiales a esta cubicación mediante un archivo excel con un formato base")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 float-right",attrs:{"small":"","outlined":"","color":"grey darken-2 text-white"},on:{"click":function($event){_vm.showCosts = !_vm.showCosts}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showCosts ? "Ocultar costos" : "Mostrar costos")+" "),_c('v-icon',{staticClass:"pl-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.showCosts ? "mdi-arrow-collapse-right" : "mdi-arrow-collapse-left")+" ")])],1)]}}])},[_c('span',[_vm._v("Esta acción permite mostrar/ocultar un panel de costos totales de la cubicación")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"small":"","outlined":"","color":"blue darken-2 text-white"},on:{"click":_vm.openModalShowInitialFillRequests}},'v-btn',attrs,false),on),[_vm._v(" Ver detalles ")])]}}])},[_c('span',[_vm._v("Esta acción permite ver el listado de solicitudes de llenado inicial realizadas en esta cubicación")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right mr-2 mt-lg-0 mt-md-3",attrs:{"small":"","outlined":"","color":"green darken-2","loading":_vm.loadingExcel},on:{"click":_vm.downloadExcelFromCubage}},'v-btn',attrs,false),on),[_vm._v(" Descargar Excel ")])]}}])},[_c('span',[_vm._v("Esta acción permite descargar un archivo excel con el resumen detallado de la cubicación")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"transition-easy-out",attrs:{"cols":"12","md":_vm.showCosts ? 10 : 12,"sm":"12"}},[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"principal"},[_vm._v(" Equipos Principales ")]),_c('v-tab',{key:"materials"},[_vm._v(" Materiales ")]),_c('v-tab',{key:"execution"},[_vm._v(" Ejecución ")]),_c('v-tab',{key:"others"},[_vm._v(" Otros ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"principal"},[_c('main-teams')],1),_c('v-tab-item',{key:"materials"},[_c('cubicle-materials')],1),_c('v-tab-item',{key:"execution"},[_c('execution')],1),_c('v-tab-item',{key:"others"},[_c('others-attachments')],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCosts),expression:"showCosts"}],staticClass:"px-0",attrs:{"cols":"12","md":"2","sm":"12"}},[_c('total-costs')],1)],1),_c('show-initial-fill-requests',{attrs:{"modalAction":_vm.dialogShowInitialFillRequests,"closeModalShowInitialFillRequests":_vm.closeModalShowInitialFillRequests}}),_c('copy-to-template-cubage',{attrs:{"modalAction":_vm.dialogCopyToTemplate,"closeModalCopyToTemplate":_vm.closeModalCopyToTemplate},on:{"materialsCopied":function($event){return _vm.getCubageMaterials({
        document_id: _vm.currentCubage.id,
      })}}}),_c('create-template-from-cubage',{attrs:{"modalAction":_vm.dialogCreateTemplateFromCubage,"closeModalCreateTemplateFromCubage":_vm.closeModalCreateTemplateFromCubage},on:{"newTemplateAdded":_vm.getTemplates}}),_c('copy-from-template',{attrs:{"modalAction":_vm.dialogCopyFromTemplate,"closeModalCopyFromTemplate":_vm.closeModalCopyFromTemplate},on:{"templateCopied":function($event){return _vm.getCubageMaterials({
        document_id: _vm.currentCubage.id,
      })}}}),_c('upload-materials-excel-from-cubage',{attrs:{"modalAction":_vm.dialogUploadMaterialsExcelFromCubage,"closeModalUploadMaterialsExcelFromCubage":_vm.closeModalUploadMaterialsExcelFromCubage},on:{"materialsUploaded":function($event){return _vm.getCubageMaterials({
        document_id: _vm.currentCubage.id,
      })}}}),_c('v-dialog',{attrs:{"max-width":"700px"},on:{"keydown":_vm.closeModalSendCubage,"click:outside":_vm.closeModalSendCubage},model:{value:(_vm.dialogSendCubage),callback:function ($$v) {_vm.dialogSendCubage=$$v},expression:"dialogSendCubage"}},[_c('v-card',[_c('v-card-title',{staticClass:"p-0"},[_c('v-toolbar',{attrs:{"color":"red darken-2","dark":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-h6"},[_vm._v("Enviar cubicación")])]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('p',{staticClass:"mt-5 font-weight-bold px-1"},[_vm._v(" ¿ Deseas cerrar esta cubicación ? una vez cerrada, se envía la solicitud de los materiales y solo podras modificar los materiales sin código ")]),(_vm.$route.query.sap_project)?_c('section',{staticClass:"text-center mt-5 pt-5"},[_c('label',{},[_vm._v(" Selecciona la etapa del proyecto para la cubicación ")]),_c('v-autocomplete',{staticClass:"w-50 mx-auto",attrs:{"placeholder":"Etapa del proyecto","items":_vm.availableStagesProjects,"item-value":"sap_code","item-text":"name"},model:{value:(_vm.form.sap_stage),callback:function ($$v) {_vm.$set(_vm.form, "sap_stage", $$v)},expression:"form.sap_stage"}})],1):_vm._e()]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","outlined":"","disabled":_vm.loadingClose},on:{"click":function($event){return _vm.closeModalSendCubage($event)}}},[_c('small',[_vm._v(" Cancelar ")])]),_c('v-btn',{staticClass:"text-white",attrs:{"disabled":_vm.loadingClose,"color":"red darken-3"},on:{"click":_vm.closeCurrentCubage}},[_c('small',[_vm._v(" Cerrar cubicación ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }